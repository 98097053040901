document.addEventListener("DOMContentLoaded", function(event) {

  phone_formatter()

})



function phone_formatter() {
  
  var phone = document.querySelector(".format_phone")

  if (document.body.contains(phone)) {

    phone.selectionStart = phone.selectionEnd = phone.value.length
    
    if (phone.value != "") {
      phone.value = format_phone(phone.value)
    }

    phone.onmouseup = function (e) {
      this.selectionStart = this.selectionEnd = this.value.length
    }

    phone.onkeyup = function (e) {

      if (e.keyCode == 37 || e.keyCode == 36) {
        this.selectionStart = this.selectionEnd = this.value.length
      }
      phone.value = format_phone(phone.value)
    }
  }
}



function format_phone(phone) {

  phone = phone.replace(/[\D\s\._]+/g, "")
  pattern = "0 000 000-00-00"

  new_phone = ""
  pattern.split("").forEach(function(char) {
    
    if (phone) {
      if (char == "0") {
        new_phone += phone[0]
        phone = phone.substr(1)
      } else {
        new_phone += char
      }
    }
  })

  return new_phone
}