import Rails from '@rails/ujs';
Rails.start();

import "./phone_formatter";



import {MDCTextField} from '@material/textfield';
const textFields = [].map.call(document.querySelectorAll('.mdc-text-field'), function(el) {
  return new MDCTextField(el);
});

// import {MDCRipple} from '@material/ripple';
// const buttons = [].map.call(document.querySelectorAll('.mdc-button'), function(el) {
//   return new MDCRipple(el);
// });



import {MDCSnackbar} from '@material/snackbar';

const alert = document.querySelector('.alert');
const notice = document.querySelector('.notice');

if (alert || notice) {
  const snackbar = new MDCSnackbar(document.querySelector('.mdc-snackbar'));

  snackbar.open()
}





